import { Typography } from "@mui/material";
import DOMPurify from "isomorphic-dompurify";
import { useEffect, useState } from "react";
import EditorDescription from "./EditorDescription";

type CommonDraftDescriptionProps = {
  analytics: any;
  id?: string;
  content: any;
  variant?: any;
  color?: string;
  className?: string;
  secondaryArgs: any;
};
const CommonDraftDescription = ({
  color = "",
  content = {},
  secondaryArgs,
  analytics = {},
  className = "",
  id = "Description",
  variant = "p3regular",
}: CommonDraftDescriptionProps) => {
  const { editState = false } = secondaryArgs;
  const { Description = "" } = content;

  const [textHtml, setTextHtml] = useState("");

  /**
   * description html passing from child component
   * @param textHtmlData string
   */
  const passingToHtml = (textHtmlData = "") => {
    setTextHtml(textHtmlData);
  };

  useEffect(() => {
    setTextHtml(Description);
  }, [Description]);

  return (
    <>
      {editState ? (
        <EditorDescription
          color={color}
          analytics={analytics}
          description={content.Description || ""}
          editState={editState}
          secondaryArgs={secondaryArgs}
          passingToHtml={passingToHtml}
        />
      ) : null}

      <Typography
        variant={variant}
        id={id}
        className={className}
        // className='ql-editor' //incase editor design not printed enable and check this class
        sx={{
          wordBreak: "break-all",
          display: editState ? "none !important" : "block",
        }}
        color={color}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(textHtml || Description),
        }}
      />
    </>
  );
};

export default CommonDraftDescription;
