import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import "quill-paste-smart";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "./EditorBubbleTheme.css";

type EditorDescriptionProps = {
  analytics: any;
  color?: string;
  optionEditor?: any;
  passingToHtml?: any;
  description?: string;
  editState?: boolean;
  secondaryArgs?: any;
};
const Link = Quill.import("formats/link");

class MyLink extends Link {
  static create(value) {
    const node = super.create(value);
    let values = this.sanitize(value);
    if (!values.startsWith("https")) {
      values = "https://" + values;
    }
    node.setAttribute("href", values);
    return node;
  }
}
Quill.register(MyLink);

const Font = ReactQuill.Quill.import("formats/font"); // <<<< ReactQuill exports it
Font.whitelist = ["Inter"]; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

const Block = Quill.import("blots/block");
class MyBlock extends Block {
  static create() {
    const node = super.create();
    // Next, customize other functions
    node.setAttribute("style", "margin: 0 0 10px 0;");
    return node;
  }
}
Quill.register(MyBlock);

const EditorDescription = (props: EditorDescriptionProps) => {
  const {
    analytics = {},
    color = "",
    description = "",
    passingToHtml = () => {},
    optionEditor = [
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      ["bold", "italic", "underline", "link"],
    ],
  } = props;

  const theme = useTheme();
  const styleInline = `.ql-container {font-family: ${theme.fontFamily.secondary}}`;

  const [value, setValue] = useState<string>("");
  const reactQuillRef = useRef<ReactQuill>(null);

  const editorText = (e: string) => {
    setValue(e);
    passingToHtml(e);
  };

  const isHTML = (str: any) => {
    const elem = document.createElement("p");
    elem.innerHTML = str;
    return elem.children.length > 0;
  };

  useEffect(() => {
    const input = document.querySelector("input[data-link]") as HTMLInputElement;
    input.dataset.link = "Enter a valid link";
    input.placeholder = "Enter a valid link";
  });

  useEffect(() => {
    if (description) {
      if (isHTML(description)) {
        setValue(description);
        passingToHtml(description);
      } else {
        setValue(`<p>${description}</p>`);
        passingToHtml(`<p>${description}</p>`);
      }
    }
  }, [description]);

  return (
    <Box color={color}>
      <style>{styleInline}</style>
      <ReactQuill
        modules={{
          toolbar: optionEditor,
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        }}
        theme='bubble'
        value={value}
        preserveWhitespace
        ref={reactQuillRef}
        bounds={`#${analytics?.prelemID}`}
        onChange={(v) => {
          // replace tabs with spaces
          editorText(v?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"));
        }}
        // onChange={editorText}
      />
    </Box>
  );
};
export default React.memo(EditorDescription);
